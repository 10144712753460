import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatsView from '@/views/StatsView.vue'
import MissionsView from '@/views/MissionsView.vue'
// import MaintenanceView from '@/views/MigrationView.vue'

const routes = [
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'Migration',
  //   component: MaintenanceView,
  // }

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
  },
  { 
    path: '/missions',
    name: 'missions',
    component: MissionsView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
