<template>
    <div class="flex justify-between my-4 rounded-2xl">
        <div class="flex gap-4 items-center">
            <div class="rounded-full h-10 w-10 ">
                <img :src="missionIcon" alt="icon" class="h-10 w-10 rounded-full">
            </div>
            <div class="flex flex-col items-start">
                <h4 class="text-white font-semibold text-lg">{{ mission.name }}</h4>
                <div class="flex gap-1">
                    <h3 class="text-white font-bold">+{{ mission.reward }}</h3>
                    <h3 class="text-primary-gray font-bold">$Loop</h3>
                </div>
            </div>
        </div>

        <div v-if="isLoading" class="flex items-end">
            <button type="button" class="text-white bg-gray-button font-semibold rounded-full px-7 py-2 mt-2 mb-2 text-center">
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </button>
        </div>
       
        <div v-if="!isMissionDone">
            <button type="button" v-on:click="goToLink" v-if="!isLinkDone && !isLoading" class="text-white bg-gray-button font-semibold rounded-full px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-gray-400': isDone }">
                <h1>Open</h1>
            </button>

            <button type="button" v-on:click="claimMission" v-else-if="isLinkDone && !isLoading" class="text-white bg-[#B01FE3] font-semibold rounded-full text-base px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-blue-button': isLinkOpened }">
                <h1>Claim</h1>
            </button>
        </div>

        <div v-if="isMissionDone" class="flex justify-center me-5">
            <img src="../assets/done.svg" alt="done">
        </div>
    </div>
</template>

<script>
import AppService from '@/service/appService';

export default {
    name: 'MissionCard',
    props: {
        mission: {
            type: Object,
            required: true
        },
        isDone: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        async goToLink() {
            if (this.isDone) return;

            // Set loading state
            this.$store.commit('addVerifyingMission', this.mission.id);
            
            // Open the link in a new tab
            window.open(this.mission.missionLink, '_blank');

            // Wait for 10 seconds
            await new Promise(resolve => setTimeout(resolve, 10000));
            
            // Set loading state to false and mark the link as done
            this.$store.commit('removeVerifyingMission', this.mission.id);

            this.$store.commit('addLinkDone', this.mission.id);
        },

        claimMission() {
            if (!this.isLinkDone) return;

            AppService.claimMission(this.$store.state.user.id, this.mission.id);
            
            this.$store.commit('removeLinkDone', this.mission.id);
            this.$store.commit('setMissionCompleted', this.mission.id);
            this.$store.commit('setBalance', this.$store.state.user.balance + this.mission.reward);
            this.$toast.success('Mission completed successfully');
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

        missionIcon() {
            return require(`@/assets/${this.mission.platform.toLowerCase()}.svg`);
        },

        isMissionDone() {
            return this.user.missionsDone.includes(this.mission.id);
        },

        isLinkDone() {
            return this.$store.state.linksDone.includes(this.mission.id);
        },

        isLoading() {
            return this.$store.getters.verifyingMission.includes(this.mission.id);
        },
    }
}
</script>