let interval = null;
const store = require('@/store/index');

const minePerSecond = 0.02;
const claimFillDurationHour = 1;

const startMining = async () => {
    interval = setInterval(() => {
        const fillTime = claimFillDurationHour * 60 * 60 * 1000;
        const user = store.default.state.user;

        if (user.lastClaimed && Date.now() - user.lastClaimed > fillTime) {
            stopMining();
            return;
        }

        const newStorage = store.default.state.user.storage + minePerSecond;

        store.default.commit('setStorage', newStorage);
    }, 1000);
};

const stopMining = () => {
    clearInterval(interval);
    interval = null;
};

export default { startMining, stopMining, interval };