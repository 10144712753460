<template>  
  <div class="bg-app min-h-screen bg-[#030534]">
    <img src="@/assets/bg.png" alt="bg" class="shadow-none absolute bottom-28">
    <div class="h-20 flex items-center justify-center bg-transparent absolute z-10 w-full">
        <img src="./assets/loop.svg" alt="" style="height: 80px; width: 120px;">
    </div>
    <div v-if="!isLoading">
        <div class="mt-20"></div>
        <router-view/>
        <BottomBar/>
    </div>
    <div class="flex flex-col justify-center items-center h-screen" v-else>
      <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 animate-spin dark:text-gray-600 fill-primary-yellow" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
import BottomBar from '@/components/BottomBar.vue';
import UserService from '@/service/userService.js'
import UserModel from '@/models/UserModel.js'
import AppService from '@/service/appService';
import mineService from '@/service/mineService.js'
import initFirebase from './firebase';
import { logEvent } from 'firebase/analytics';

export default {
  name: 'App',
  components: {
    BottomBar,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  mounted() {
      this.isLoading = true;

      // const id = 1155175682; 
      // const name = 'test123';

      const { user, start_param } = window.Telegram.WebApp.initDataUnsafe;

      const id = user.id;
      const name = `${user.first_name} ${user.last_name}`;

      const { analytics } = initFirebase();

      Promise.all([
        UserService.getUser(id),
        AppService.getMissions()
      ]).then(([loginData, missionsData]) => {
        // Handle user data
          const refId = start_param;

          if (loginData == undefined) {
            return UserService.createUser({
              id,
              name,
              refId,
            }).then(async () => {
              const res = await UserService.getUser(id);

              const userData = res.user
              const nextClaim = res.nextClaim;
              const nextDailyClaim = res.nextDailyClaim;
              const nextWheelClaim = res.nextWheelClaim;

              const currentUser = UserModel.fromJson(userData);
              const nextClaimDate = new Date(nextClaim);
              const nextDailyClaimDate = new Date(nextDailyClaim);
              const nextWheelClaimDate = new Date(nextWheelClaim);
              
              this.$store.commit('setUser', currentUser);

              this.$store.commit('setNextClaim', nextClaimDate);
              this.$store.commit('setNextWheelClaim', nextWheelClaimDate);

              this.$store.commit('setNextDailyClaim', nextDailyClaimDate);

              logEvent(analytics, 'user_created', {
                user_id: res.user.id,
              });

              return { user: currentUser, missionsData, nextClaimDate, nextWheelClaimDate, nextDailyClaimDate };
            });
          } else {
              const userData = loginData.user;
              const nextClaim = loginData.nextClaim;
              const nextDailyClaim = loginData.nextDailyClaim;
              const nextWheelClaim = loginData.nextWheelClaim;

              const currentUser = UserModel.fromJson(userData);
              const nextClaimDate = new Date(nextClaim);
              const nextDailyClaimDate = new Date(nextDailyClaim);
              const nextWheelClaimDate = new Date(nextWheelClaim);
              
              this.$store.commit('setUser', currentUser);

              this.$store.commit('setNextClaim', nextClaimDate);
              this.$store.commit('setNextWheelClaim', nextWheelClaimDate);

              this.$store.commit('setNextDailyClaim', nextDailyClaimDate);

              logEvent(analytics, 'user_logged_in', { 
                user_id: currentUser.id,
              });

              return { user: currentUser, missionsData, nextClaimDate, nextWheelClaimDate, nextDailyClaimDate };
          }
      }).then(({ user, missionsData, nextClaimDate, nextWheelClaimDate, nextDailyClaimDate }) => {

        // Handle last claimed logic
        const startable = Date.now() < this.$store.state.nextClaim;
        const minePerSecond = 0.02;

        if (startable) {
          mineService.startMining(user.storage, minePerSecond);
        }

        // Handle missions data
        const missions = missionsData.missions;
        this.$store.commit('setMissions', missions);

        if(user.lastClaimed) {
          const countDownInterval = setInterval(() => {
            const currentTime = new Date().getTime();
            const timeLeft = nextClaimDate - currentTime;
            
            if (timeLeft < 0) {
              this.$store.commit('setCountdown', '00:00:00');

              clearInterval(this.$store.state.countDownInterval);

              return;
            }

            const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

            const countdown = `${hours}:${minutes}:${seconds}`;

            this.$store.commit('setCountdown', countdown);
          }, 1000);

          this.$store.commit('setCountdownInterval', countDownInterval);
        }

          const dailyCountDownInterval = setInterval(() => {
              const currentTime = new Date().getTime();
              const timeLeft = nextDailyClaimDate - currentTime;
              
              if (timeLeft < 0) {
                this.$store.commit('setDailyCountdown', '00:00:00');

                clearInterval(this.$store.state.dailyCountDownInterval);

                return;
              }

              const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
              const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
              const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

              this.$store.commit('setDailyCountdown', `${hours}:${minutes}:${seconds}`);
          }, 1000);

          this.$store.commit('setDailyCountDownInterval', dailyCountDownInterval);

          const wheelCountdownInterval = setInterval(() => {
              const currentTime = new Date().getTime();
              const timeLeft = nextWheelClaimDate - currentTime;
              
              if (timeLeft < 0) {
                this.$store.commit('setWheelCountDown', '00:00:00');

                clearInterval(this.$store.state.wheelCountDownInterval);

                return;
              }

              const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
              const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
              const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

              this.$store.commit('setWheelCountDown', `${hours}:${minutes}:${seconds}`);
          }, 1000);

          this.$store.commit('setWheelCountDownInterval', wheelCountdownInterval);

          setInterval(() => {
            let canClaim = new Date() > new Date(this.$store.state.nextClaim);

            if (canClaim) {
              this.$store.commit('setCanClaim', true);
            } else {
              this.$store.commit('setCanClaim', false);
            }
          }, 1000);

          this.isLoading = false;
      }).catch((error) => {
        console.error('Error fetching data:', error);
        this.isLoading = false;
      });
  },
}

</script>

<style>
* {
  touch-action: manipulation;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Manrope", sans-serif;
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.bg-app {
  background-color: black;
  position: relative;
  overflow: hidden;
}
</style>
