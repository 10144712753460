import FriendModel from "./FriendModel";

class UserModel {
    constructor(id, name, friends, balance, storage, minePerSecond, boxLevel, claimLevel, missionsDone, lastClaimed, claimFillDurationHour, dailyClaims, firstLogin, refClaimLevel, friendCount) {
        this.id = id;
        this.name = name;
        this.friends = friends;
        this.balance = balance;
        this.storage = storage;
        this.minePerSecond = minePerSecond;
        this.boxLevel = boxLevel;
        this.claimLevel = claimLevel,
        this.missionsDone = missionsDone;
        this.lastClaimed = lastClaimed;
        this.claimFillDurationHour = claimFillDurationHour;
        this.dailyClaims = dailyClaims;
        this.firstLogin = firstLogin;
        this.refClaimLevel = refClaimLevel;
        this.friendCount = friendCount;
    }

    static fromJson(json) {
        let friends = json.friends.map(friend => {
            return FriendModel.fromJson(friend);
        });

        return new UserModel(
            json.id,
            json.name,
            friends,
            json.balance,
            json.storage,
            json.minePerSecond,
            json.boxLevel,
            json.claimLevel,
            json.missionsDone,
            json.lastClaimed,
            json.claimFillDurationHour,
            json.dailyClaims,
            json.firstLogin,
            json.refClaimLevel,
            json.friendCount,
        );
    }
}

export default UserModel;